// jQuery for alert messages
$(document).ready(function(){
    var thisAlert = $(".alert");
    if (thisAlert.attr('id') != 'message_success') {
      thisAlert.show().delay(4000).fadeOut();
    }
});



// jQuery for Hiding/Showing Account Sidebar
function showing() {
	$( "#show" ).toggle();
  	$( "#toggle" ).toggleClass( "rotate" );
}


// Hides and shows Details panes
$('.menu .item').tab();


// Magnific popup for images
$('#myLargeModalImage').magnificPopup({
  type: 'image'
  // other options
});


// Magnific popup for images
$('.gallery-container').magnificPopup({
  delegate: '#event-photo',
  type: 'image',
  gallery: {enabled:true},
  image: {
       titleSrc: function(item) {
       return item.el.parents('.gallery-container').find('#event-name').html();
       }
  }
  // other options
});



$('.dropdown-menu input, .dropdown-menu label').click(function(e) {
    e.stopPropagation();
});


$('.ui.dropdown')
  .dropdown({
    action: 'hide'
  });



// Push Navigation

( function( window ) {

'use strict';

// class helper functions from bonzo https://github.com/ded/bonzo

function classReg( className ) {
  return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
}

// classList support for class management
// altho to be fair, the api sucks because it won't accept multiple classes at once
var hasClass, addClass, removeClass;

if ( 'classList' in document.documentElement ) {
  hasClass = function( elem, c ) {
    return elem.classList.contains( c );
  };
  addClass = function( elem, c ) {
    elem.classList.add( c );
  };
  removeClass = function( elem, c ) {
    elem.classList.remove( c );
  };
}
else {
  hasClass = function( elem, c ) {
    return classReg( c ).test( elem.className );
  };
  addClass = function( elem, c ) {
    if ( !hasClass( elem, c ) ) {
      elem.className = elem.className + ' ' + c;
    }
  };
  removeClass = function( elem, c ) {
    elem.className = elem.className.replace( classReg( c ), ' ' );
  };
}

function toggleClass( elem, c ) {
  var fn = hasClass( elem, c ) ? removeClass : addClass;
  fn( elem, c );
}

var classie = {
  // full names
  hasClass: hasClass,
  addClass: addClass,
  removeClass: removeClass,
  toggleClass: toggleClass,
  // short names
  has: hasClass,
  add: addClass,
  remove: removeClass,
  toggle: toggleClass
};

// transport
if ( typeof define === 'function' && define.amd ) {
  // AMD
  define( classie );
} else if ( typeof exports === 'object' ) {
  // CommonJS
  module.exports = classie;
} else {
  // browser global
  window.classie = classie;
}

})( window );


var menuLeft = document.getElementById( 'cbp-spmenu-s1' ),
showLeftPush = document.getElementById( 'showLeftPush' ),
body = document.body;


window.onload = function(){  
  if (showLeftPush) {
    showLeftPush.onclick = function() {
      $(this).toggleClass('active');
      $('body').toggleClass('cbp-spmenu-push-toright');
      $('#cbp-spmenu-s1').toggleClass('cbp-spmenu-open');
      $('#showLeftPush div').toggleClass('active-ham');
      $('.intercom-launcher-button').toggleClass('hide');
      $('html').toggleClass('drawerOpen');
      $('.menuOverlay').toggleClass('active');
    }
  }
};  

function toggleRightPanel() {
  $( ".st-default-search-input" ).toggle();
  $( "#intercom-container .intercom-launcher-enabled").toggle();
  $(".fa-search").toggleClass("fa-times");
}

// Dismiss Mobile Hamburger Nav on Tap


$('.tag.example .ui.dropdown').dropdown({
    allowAdditions: true
  });

$('.btn-avatar').mouseover(function(){
    $('#avatar-text').show();
});
$('.btn-avatar').mouseleave(function(){
    $('#avatar-text').hide();
});


//Add Overlay
$(function(){
  $('body').append('<div class="menuOverlay"></div>');

  $('.menuOverlay').on('click', function(){
    $('#showLeftPush').click();
  });

  $(window).resize(function(){
    $('#showLeftPush.active').click();
   }); 

});


// add floating css3 animation on honver
$("#floatEffect").hover(
  function () {
    $(this).addClass('floating');
  }, 
  function () {
    $(this).removeClass('floating');
  }
);

// tooltips
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
});

$( ".account" ).click(function() {
  $(".angles").toggleClass( "fa-angle-down" );
  $(".angles").toggleClass( "fa-angle-up" );
});

// if clicked outside dropdown, flip caret back
$(window).click(function() {
  if($(".angles").hasClass( "fa-angle-up" ) == true) {
      $(".angles").removeClass( "fa-angle-up" );
      $(".angles").addClass( "fa-angle-down" );
  }
});



var dropActive = false;

function showMenu() {
  $('#major-menu').css('top', '56px');
  $('#major-menu').toggle();
  if(dropActive) {
    dropActive = false;
    $('#hidden-overlay').fadeOut();
    $('.angles').removeClass("fa-angle-up");
    $(".angles").toggleClass( "fa-angle-down" );

  } else {
    dropActive = true;
    $('#hidden-overlay').fadeIn();
    $('.angles').removeClass("fa-angle-down");
    $(".angles").toggleClass( "fa-angle-up" );
  }
}

function showContent() {
    dropActive = false;
    $('#major-menu').hide();
    $('#hidden-overlay').fadeOut();
    $('.angles').removeClass("fa-angle-up");
    $(".angles").toggleClass( "fa-angle-down" );
}